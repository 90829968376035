@tailwind base;
@tailwind components;
@tailwind utilities;

body,
.Toastify__toast-body {
    font-family: "Noto Sans Thai", sans-serif;
    font-size: 15px;
    color: #111824;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Noto Sans Thai", sans-serif;
}

.Toastify__toast-body {
    color: #333;
}

.side-nav li:not(.main-page-home) .active {
    background-color: rgb(249 249 249);
    border-left-width: 4px;
    --tw-bg-opacity: 1;
    color: #1025a4;
    border-color: #1025a4;
}
.MuiSnackbarContent-root {
    background: #fff !important;
    color: #333 !important;
}

.error-doc * {
    z-index: 999;
    position: relative;
}

.error-doc:before {
    content: "404";
    position: absolute;
    top: 50%;
    left: 50%;
    color: #f3f3f3;
    font-weight: 700;
    font-size: 20em;
    line-height: 1;
    transform: translate(-50%, -50%);
}

.logo svg {
    max-width: 100%;
}

.main-side-bar {
/*
    width: 220px;
    min-width: 220px;
    */
}

.main-side-bar.collapsed .menu-link-warp, .main-side-bar.collapsed .left-sidebar-title {
    display: none;
}
.main-side-bar.collapsed {
    width: 80px;
    min-width: 80px;
}

.main-side-bar.collapsed .logo svg {
    padding: 0.84em 0;
}

.main-side-bar.collapsed li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.main-side-bar.collapsed li:not(.main-page-home) .active .menu-svg-warp {
    transform: translate(-4px, 0px);
}

.fc .fc-button-primary {
    background-color: #3730a3 !important;
    border-color: #3730a3 !important;
    color: var(--fc-button-text-color);
}
.fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width)/2) solid #3730a3 !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #1025a4;
}
.iupload-warp label {
    max-width: 100% !important;
    height: 140px;
    padding: 2rem;
    margin-bottom: 0.5em;
    border: dashed 2px #b5b5b5;
}
.iupload-warp path {
    fill: #282828;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(40 109 255 / 7%) !important;
}

td .MuiInputBase-root:before {
    border: none !important;
}

.remove-spin::-webkit-inner-spin-button,
  .remove-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .center-input input {
    text-align: center;
}
.custom-card-bg:before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(134 132 170) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}
.custom-card-bg:after {
    content: "";
    position: absolute;
    width: 221px;
    height: 219px;
    background: linear-gradient(210.04deg, rgb(121 96 172) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
}
.custom-card-bg-2:before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(255 140 32) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}
.custom-card-bg-2:after {
    content: "";
    position: absolute;
    width: 221px;
    height: 219px;
    background: linear-gradient(210.04deg, rgb(253 112 112) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
}
@media only screen and (max-width: 768px) {
    .error-doc h1 {
        font-size: 1.2em;
    }
    .error-doc:before {
        font-size: 12em;
    }
    .main-doc:before {
        font-size: 4em;
    }
}


.bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 194 0) !important;
    color: #000 !important;
  }